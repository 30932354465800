import { CTA, styled, Text, TextTag, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { forwardRef, useEffect, useState } from 'react';
import { getLang } from '../translations/getLang';
import { useBreakpointReached } from '../utils/use-breakpoint-reached';

const Wrapper = styled.div`
  padding: 0 ${(props) => props.theme.size.grid002};
  width: 100%;
  min-height: ${({height}) => height};
  display: grid;
  justify-items: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-items: center;
  align-items: center;
  position: relative;
`;

const StyledCTA = styled(CTA)`
  :hover:not(:disabled),
  :active:not(:disabled),
  :focus:not(:disabled):not(:focus-visible) {
    background: #00b0f0;
  }
`;

export const Content = forwardRef((_props, ref) => {
  const lang = getLang();
  const translations = require(`../translations/${lang}.json`);
  const handleClick = () => { window.smartOverlay.trigger({action: 'Close'}); };

  const [height, setHeight] = useState("100vh");

  useEffect(() => {
    if (ref?.current && height === "100vh") {
      setHeight(`${Math.round(ref.current.offsetHeight * 3)}px`);
    }
  }, [ref, height]);

  return (
    <Wrapper height={height}>
      <Container ref={ref}>
        <Text tag={TextTag.h1} appearance={useBreakpointReached() ? TokenTextAppearance.headline600 : TokenTextAppearance.headline500} textAlign="center" bold>
          { translations.header }
        </Text>
        <Text tag={TextTag.p} appearance={useBreakpointReached() ? TokenTextAppearance.headline200 : TokenTextAppearance.headline100} textAlign="center" bold>
          { translations.copy }
        </Text>
        <StyledCTA
          tag="button"
          emphasis="secondary"
          size={useBreakpointReached() ? "large" : "small"}
          onClick={handleClick}
        >
          { translations.cta }
        </StyledCTA>
      </Container>
    </Wrapper>
  );
});
import { styled } from "@volkswagen-onehub/components-core";
import { useContext } from "react";
import { Heart } from "./Heart";
import { OffsetContext } from "../App";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  overflow: hidden;
`;

const TopContainer = styled.div`
  height: 100%;
  grid-row: 1 / span 1;
  position: relative;
  transform: translateY(-15%);
`;

const BottomContainer = styled.div`
  height: 100%;
  grid-row: span 1 / -1;
  position: relative;
  transform: translateY(20%);
`;

export const Background = () => {
  const offsetValues = useContext(OffsetContext);

  const { width }= offsetValues;

  const small = Math.round(width * 0.2) < 30 ? Math.round(width * 0.2) : 30;
  const medium = Math.round(width * 0.3) < 60 ? Math.round(width * 0.3) : 60;
  const large = Math.round(width * 0.4) < 80 ? Math.round(width * 0.4) : 80;

  return (
    <Container role="presentation">
      <TopContainer>
        <Heart opacity={100} size={large} rotate={30} position={{bottom: "36%", left: "92%"}} />
        <Heart opacity={100} size={large} rotate={-30} position={{bottom: "-2%", left: "10%"}} />
        <Heart opacity={60} size={medium} rotate={30} position={{bottom: "58%", left: "13%"}} />
        <Heart opacity={60} size={medium} rotate={15} position={{bottom: "23%", left: "63%"}} />
        <Heart opacity={30} size={small} rotate={25} position={{bottom: "40%", left: "30%"}} />
        <Heart opacity={30} size={small} rotate={30} position={{bottom: "13%", left: "87%"}} />
        <Heart opacity={30} size={small} rotate={-32} position={{bottom: "12%", left: "45%"}} />
      </TopContainer>

      <BottomContainer>
        <Heart opacity={100} size={large} rotate={20} position={{top: "47%", left: "79%"}} />
        <Heart opacity={100} size={large} rotate={-36} position={{top: "75%", left: "8%"}} />
        <Heart opacity={60} size={medium} rotate={20} position={{top: "25%", left: "3%"}} />
        <Heart opacity={60} size={medium} rotate={-25} position={{top: "11%", left: "98%"}} />
        <Heart opacity={60} size={medium} rotate={-4} position={{top: "66%", left: "43%"}} />
        <Heart opacity={60} size={medium} rotate={18} position={{top: "92%", left: "67%"}} />
        <Heart opacity={30} size={small} rotate={15} position={{top: "34%", left: "41%"}} />
        <Heart opacity={30} size={small} rotate={-35} position={{top: "75%", left: "99%"}} />
        <Heart opacity={30} size={small} rotate={-36} position={{top: "96%", left: "32%"}} />
      </BottomContainer>
    </Container>
  );
};
import { useState, useEffect } from "react";
import { Breakpoints } from "@volkswagen-onehub/components-core";

export const useBreakpointReached = (breakpoint = Breakpoints.b560) => {
    const [breakpointReached, setBreakpointReached] = useState(!Number.isFinite(breakpoint));

    useEffect(() => {
        if (!Number.isFinite(breakpoint)) return;

        const mql = window.matchMedia(`(min-width: ${breakpoint}px`);
        setBreakpointReached(mql.matches);

        const handleChangeListener = (e) => {
            setBreakpointReached(e.matches);
        };

        mql.addEventListener &&
            mql.addEventListener("change", handleChangeListener);

        return () => mql.removeEventListener("change", handleChangeListener);
    }, [breakpoint]);

    return breakpointReached;
};

import './fonts/index.css';
import './App.css';
import { IntegratorRoot, styled, ThemeProvider } from '@volkswagen-onehub/components-core';
import { Background } from './components/Background';
import { MovingFrame } from './components/MovingFrame';
import { Content } from './components/Content';
import { createContext, createRef, useEffect, useMemo, useState } from 'react';
// import { DesignRef } from './reference/DesignRef';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgb(0, 30, 80);
`;

export const OffsetContext = createContext(undefined);

function App() {
  const contentRef = createRef();
  const [ offsetValues, setOffsetValues ] = useState(undefined);

  useEffect(() => {
    if (contentRef?.current && !offsetValues) {
      const viewportOffset = contentRef?.current.getBoundingClientRect();
      setOffsetValues(viewportOffset);
    };
  }, [contentRef, offsetValues])

  const OffsetContextValue = useMemo(() => offsetValues, [offsetValues]);

  return (
    <IntegratorRoot>
      <ThemeProvider theme="inverted" direction="rtl">
        {/* <DesignRef /> */}
        <OffsetContext.Provider value={OffsetContextValue} >
          <Container>
            {OffsetContextValue ? <Background /> : null}
            <MovingFrame />
            <Content ref={contentRef} />
          </Container>
        </OffsetContext.Provider>
      </ThemeProvider>
    </IntegratorRoot>
  );
};

export default App;

export const getLang = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  switch (urlParams.get('lang')) {
    case "fr":
    case "fr_ca":
      return "fr";
    default:
      return "en"
  }
};
import { styled } from "@volkswagen-onehub/components-core";
import { HeartFilled } from "@volkswagen-onehub/icons-core";

const HeartWrapper = styled.div`
  position: absolute;
  ${({ position }) => position.top ? `top: ${position.top};` : null}
  ${({ position }) => position.left ? `left: ${position.left};` : null}
  ${({ position }) => position.bottom ? `bottom: ${position.bottom};` : null}
  ${({ position }) => position.right ? `right: ${position.right};` : null}
  transform: translate(-50%, -50%);
  ${({highlight}) => highlight ? "border: 1px dotted red;" : null}
`;

const HeartElement = styled(HeartFilled)`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  color: ${({highlight}) => highlight ? "#e4002c" : "#00b0f0"} ;
  opacity: ${({opacity}) => opacity}%;
  transform: rotate(${({rotate}) => rotate}deg);
  transform-origin: center;
  position: relative;
`;

export const Heart = ({opacity, size, rotate, position, highlight}) => {
  return (
    <HeartWrapper position={position} highlight={highlight}>
      <HeartElement opacity={opacity} size={size} rotate={rotate} highlight={highlight}/>
    </HeartWrapper>
  );
};

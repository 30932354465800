import { styled, TopBar } from "@volkswagen-onehub/components-core";
import { Logo } from "@volkswagen-onehub/icons-core";
import React from "react";

const TopBarWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  > div {
    background-color: initial;
  }
`;

const StyledLogo = styled(Logo)`
  --logo-size: 56px !important;
  --margin-size: 14px !important;
  // --logo-size: 48px !important;
  // --margin-size: 12px !important;
`;

export const MovingFrame = () => {
  return (
    <TopBarWrapper>
      <TopBar logo={<StyledLogo variant="large" />} />
    </TopBarWrapper>
  );
};